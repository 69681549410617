<template>
  <aside class="module-lessons-list row my-n4">
    <div class="col-lg-3 py-4" v-for="(lesson, i) in lessons" :key="i">
      <component
        class="text-decoration-none align-items-center"
        :is="lesson.is_blocked ? 'div' : 'router-link'"
        :to="{
          name: 'lesson',
          params: { lesson: lesson.id, id: lesson.module.id }
        }"
      >
        <lesson-thumb :lesson="lesson" />

        <div class="d-flex align-items-center">
          <div class="flex-fill pr-2">
            <!-- <span v-if="!hideNumber" class="badge">Aula {{ i + 1 }}</span> -->
            <h6 class="mt-2">{{ lesson.name }}</h6>
          </div>

          <div
            v-if="lesson.progress && lesson.progress.finished_at"
            class="circle check"
          >
            <img src="https://img.icons8.com/metro/16/FFFFFF/checkmark.png" />
          </div>
        </div>
      </component>
    </div>
  </aside>
</template>

<script>
import LessonThumb from "@/components/LessonThumb";

export default {
  components: { LessonThumb },
  props: {
    lessons: {
      type: Array,
      required: true
    },
    hideNumber: Boolean
  },
  data() {
    return {
      searchTerm: ""
    };
  },
  methods: {
    duration(lesson) {
      if (!lesson.duration) return "";
      let [h, m, s] = lesson.duration.split(":");
      if (h === "00") {
        return `${m}:${s}`;
      } else {
        return lesson.duration;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.module-lessons-list {
  a {
    display: block;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  .circle {
    min-width: 24px;
    min-height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }
  .check {
    background: #04c56b;
  }

  .badge {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    background: rgb(129, 127, 128);
    color: white;
  }

  p {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
