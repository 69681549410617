<template>
  <div class="view-search py-5 container-fluid">
    <h5 class="mb-2">Buscando por: {{ $route.query.search }}</h5>

    <action-dispatcher action="lessons/search" :parameters="params">
      <em class="text-secondary d-block small pb-5">
        <template v-if="lessons.length === 0">Nenhum resultado</template>
        <template v-else>{{ count }} resultados</template>
      </em>

      <lessons-list :lessons="lessons" hide-number />
    </action-dispatcher>

    <pagination class="mt-5" v-model="params" :count="count" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/Pagination";
import LessonsList from "@/components/module/LessonsList";

export default {
  components: { Pagination, LessonsList },
  computed: {
    ...mapState("lessons", ["lessons", "count"]),
    params() {
      const limit = 6;
      return {
        ...this.$route.query,
        limit,
        offset: limit * (parseInt(this.$route.query.page) - 1) || 0
      };
    }
  }
};
</script>
